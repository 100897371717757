import React from "react";
import "./GptCard.css";
import Header from "./Header";
import Gpt from "./Gpt";

function GptCard() {
  return (
    <>
      <div className="background">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // viewBox="0 0 1440 800"
          preserveAspectRatio="xMidYMid slice"
          width="100%"
          height="100%"
        >
          <path
            fill="none"
            stroke="rgba(124, 66, 35, 0.15)"
            strokeWidth="3"
            d="M 0 50 Q 300 150 600 80 T 1200 150 T 1600 150"
          />
          <path
            fill="none"
            stroke="rgba(124, 66, 35, 0.25)"
            strokeWidth="4"
            d="M 0 300 Q 200 350 400 450 T 800 500 T 1200 600 T 1600 800"
          />
          <path
            fill="none"
            stroke="rgba(124, 66, 35, 0.15)"
            strokeWidth="3"
            d="M 0 800 Q 300 500 600 650 T 1200 600 T 1600 350"
          />
        </svg>
      </div>
      <div className="GptCardContainer">
        <div className="GptCard">
          <Header />
          <Gpt />
        </div>
      </div>
    </>
  );
}
export default GptCard;
