import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Header.css";

function Header() {
  return (
    <div className="headingContainer">
      <Typography
        variant="h5"
        component="div"
        sx={{
          color: "#EAA243",
          fontWeight: "bold",
          fontSize: "1.875rem",
          lineHeight: "2.25rem",
        }}
      >
        Label GPT.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        sx={{
          color: "#7C4223",
          // fontStyle: "italic",
          fontWeight: "600",
        }}
      >
        Drill Down to the Details of Your Food
      </Typography>
    </div>
  );
}

export default Header;
