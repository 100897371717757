export const gptTextFirebaseFunction = async (content) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_TEXT_FIREBASE_FUNCTION_URL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: content }),
      }
    );
    return response.json();
  } catch (error) {
    console.error("Error calling function:", error);
    return {};
  }
};

export const gptImageFirebaseFunction = async (content, imageData) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_IMAGE_FIREBASE_FUNCTION_URL,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ imageData: imageData, content: content }),
      }
    );
    return response.json();
  } catch (error) {
    console.error("Error calling function:", error);
    return {};
  }
};
