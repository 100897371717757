import { useState } from "react";
import PhotoCameraBackRoundedIcon from "@mui/icons-material/PhotoCameraBackRounded";
import KeyboardRoundedIcon from "@mui/icons-material/KeyboardRounded";
import Button from "@mui/material/Button";
import { FileUpload } from "primereact/fileupload";
import TextField from "@mui/material/TextField";
import { IconButton } from "@mui/material";
import "./Gpt.css";

import {
  gptTextFirebaseFunction,
  gptImageFirebaseFunction,
} from "../Utils/openAi";

const FoodLabelAnalysis = ({ jsonData }) => {
  // Remove any prefix (like ```json) and parse the JSON
  const cleanJsonData = jsonData.replace(/```json|\s*```/g, "").trim();
  const data = JSON.parse(cleanJsonData);
  if (data.error) {
    return (
      <div className="food-label-analysis error">
        <h1>Error</h1>
        <p>{data.conclusion}</p>
      </div>
    );
  }
  return (
    <div className="food-label-analysis">
      <h1>{data.heading}</h1>
      <div className="rating">
        Overall Healthiness Rating: {data["overall Healthiness Rating"]}/10
      </div>
      <h2>Ingredient Details</h2>
      <ul>
        {Object.keys(data.ingredientDetail).map((ingredient) => (
          <li key={ingredient}>
            <strong>{ingredient}</strong>: {data.ingredientDetail[ingredient]}
          </li>
        ))}
      </ul>
      <h2>Conclusion</h2>
      <p>{data.conclusion}</p>
    </div>
  );
};

function Gpt() {
  const [inputText, setInputText] = useState("");
  const [image, setImage] = useState(null);
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeIcon, setActiveIcon] = useState("camera");
  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result.split(",")[1]); // Base64 string without the header
    };
    reader.readAsDataURL(e.files[0]); // Conv
  };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const content = inputText;
      let res;
      if (image) res = await gptImageFirebaseFunction(content, image);
      else res = await gptTextFirebaseFunction(content);
      setResult(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="GptContainer">
      <div className="GptInputContainer">
        {activeIcon === "camera" ? (
          <>
            <FileUpload
              name="demo[]"
              onSelect={(selectEvent) => {
                console.log(selectEvent);
                handleImageChange(selectEvent);
              }}
              accept="image/*"
              emptyTemplate={
                <p className="m-0">Drag and drop files to here to upload.</p>
              }
              style={{ marginBottom: "2vh" }}
            />
          </>
        ) : (
          <TextField
            id="outlined-multiline-static"
            placeholder="Enter the Ingredients of Your Food Product. Example: Water - 45%, Protein - 30%..."
            multiline
            className="GptTextInput"
            value={inputText}
            onChange={handleTextChange}
            rows={6}
          />
        )}
        <div className="GptToggleContainer">
          <div className="toggle-icon-container">
            <IconButton
              onClick={() => setActiveIcon("camera")}
              className={`icon-button ${
                activeIcon === "camera" ? "active" : ""
              }`}
            >
              <PhotoCameraBackRoundedIcon />
            </IconButton>
            <IconButton
              onClick={() => setActiveIcon("keyboard")}
              className={`icon-button ${
                activeIcon === "keyboard" ? "active" : ""
              }`}
            >
              <KeyboardRoundedIcon />
            </IconButton>
          </div>
          {/* <Button
            variant="contained"
            style={{
              textTransform: "none",
            }}
            disabled={loading}
            onClick={handleToggle}
          >
            <PhotoCameraBackRoundedIcon />
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
            }}
            disabled={loading}
            onClick={handleToggle}
          >
            <KeyboardRoundedIcon />
          </Button> */}
        </div>
      </div>
      <div className="GptConnectingInput"></div>
      <div className="GptButton">
        <Button
          variant="contained"
          style={{
            backgroundColor: "#aa6743",
            width: "100%",
            textTransform: "none",
            padding: "3%",
            color: "white",
          }}
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Processing..." : "Submit"}
        </Button>
      </div>
      <div className="GptConnectingOutput"></div>
      <div className="GptOutputContainer">
        {result != "" ? (
          <div className="GptTextOutput">
            <FoodLabelAnalysis jsonData={result} />
          </div>
        ) : (
          <TextField
            id="outlined-multiline-static"
            multiline
            className={`GptTextOutput`}
            rows={6}
            value=""
          />
        )}
      </div>
    </div>
  );
}

export default Gpt;
