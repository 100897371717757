import "./App.css";
import LandingPage from "./components/LandingPage";
import GptCard from "./components/GptCard";

import "primereact/resources/themes/lara-light-cyan/theme.css";
function App() {
  return <GptCard />;
}

export default App;
